//#region deleteAddress
export const FUNC_CREATE_REVIEW = "createReview";
export const TYPE_CREATE_REVIEW = "CreateReviewInput!";
export const INPUT_CREATE_REVIEW = "createReviewInput";
export const RETURN_CREATE_REVIEW = `{
  _id
user{
_id
name
image
}
text
rating
createdAt
}`;
//#endregion
