import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import {
  INPUT_PAGINATION,
  TYPE_PAGINATION
} from "@shared/graphql/shared.graphql";
import { PaginationOptions } from "@shared/models/Graphql.interfaces";
import { RETURN_CACHED_ATTRIBUTES } from "@shared/services/product-list/product-list.graphql";
import { GetCachedAttributesEnResponse } from "@shared/services/product-list/product-list.model";
import { Observable } from "rxjs";
import {
  FUNC_CACHED_ATTRIBUTES_COLLECTION,
  FUNC_GET_ALL_COLLECTIONS,
  FUNC_GET_COLLECTION,
  FUNC_GET_COLLECTION_COUNT,
  INPUT_CACHED_ATTRIBUTES_COLLECTION,
  INPUT_GET_COLLECTIONS,
  INPUT_GET_COLLECTION_COUNT,
  RETURN_GET_ALL_COLLECTIONS,
  RETURN_GET_COLLECTION,
  RETURN_GET_COLLECTION_COUNT,
  TYPE_CACHED_ATTRIBUTES_COLLECTION,
  TYPE_GET_COLLECTIONS,
  TYPE_GET_COLLECTION_COUNT
} from "../models/collection.graphql";
import {
  AttributesByCollectionInput,
  FilterCollectionInput,
  FilterCollectionsCountResponse,
  GetAllCollectionsResponse,
  GetCachedAttributesByCollectionEnResponse,
  GetCollectionInput,
  GetCollectionResponse
} from "../models/collection.model";

@Injectable({ providedIn: "root" })
export class CollectionApi {
  constructor(private readonly baseService: BaseService) {}

  getAllCollections(
    variables: PaginationOptions
  ): Observable<GetAllCollectionsResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_GET_ALL_COLLECTIONS;
    input.return = RETURN_GET_ALL_COLLECTIONS;
    input.pagination = INPUT_PAGINATION;
    input.paginationType = TYPE_PAGINATION;
    input.variables = variables;
    return this.baseService.generalQueryFull(input);
  }

  geCollection(
    getCollectionInput: FilterCollectionInput
  ): Observable<GetCollectionResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_GET_COLLECTION;
    input.variable = INPUT_GET_COLLECTIONS;
    input.type = TYPE_GET_COLLECTIONS;
    input.return = RETURN_GET_COLLECTION;
    input.variables = getCollectionInput;
    return this.baseService.generalQueryFull(input);
  }

  getCachedAttributesByCollectionId(
    variables: AttributesByCollectionInput
  ): Observable<GetCachedAttributesByCollectionEnResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_CACHED_ATTRIBUTES_COLLECTION;
    input.return = RETURN_CACHED_ATTRIBUTES;
    input.type = TYPE_CACHED_ATTRIBUTES_COLLECTION;
    input.variable = INPUT_CACHED_ATTRIBUTES_COLLECTION;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }

  getFilteredCollectionCount(
    variables: FilterCollectionInput
  ): Observable<FilterCollectionsCountResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_GET_COLLECTION_COUNT;
    input.return = RETURN_GET_COLLECTION_COUNT;
    input.type = TYPE_GET_COLLECTION_COUNT;
    input.variable = INPUT_GET_COLLECTION_COUNT;
    input.variables = variables;
    return this.baseService.generalQueryFull(input);
  }
}
