import { translateReturn } from "@shared/utils/utils.methods";

//#region collections
export const FUNC_GET_ALL_COLLECTIONS = "collections";
// export const TYPE_GET_ALL_COLLECTIONS = "AddRemoveFavoriteInput!";
// export const INPUT_GET_ALL_COLLECTIONS = "addRemoveFavoriteInput";
export const RETURN_GET_ALL_COLLECTIONS = `{
  _id
  ${translateReturn("title")}
  ${translateReturn("description")}
  image
   slug


}`;
//#endregion

//#region filterCollections
export const FUNC_GET_COLLECTION = "filterCollections";
export const TYPE_GET_COLLECTIONS = "FilterCollectionInput!";
export const INPUT_GET_COLLECTIONS = "filterCollectionInput";
export const RETURN_GET_COLLECTION = `{
  _id
    ${translateReturn("title")}
    ${translateReturn("description")}
    image
    banner
    product{
      _id
      name
      egPrice
      images{
        egExtraPrice
        path
      }
    }
}`;
//#endregion

//#region attributes
export const FUNC_CACHED_ATTRIBUTES_COLLECTION =
  "getCachedAttributesByCollection";
export const TYPE_CACHED_ATTRIBUTES_COLLECTION = "AttributesByCollectionInput!";
export const INPUT_CACHED_ATTRIBUTES_COLLECTION = "attributesByCollectionInput";
export const RETURN_CACHED_ATTRIBUTES_COLLECTION = `{
  _id
  attributesCacheDate
  categoryId
  cachedAttributes{
    en{
      spec
      specValue
    }
    ar{
      arSpec
      arSpecValue
    }

}
}`;
//#endregion

//#region filterCollectionsCount
export const FUNC_GET_COLLECTION_COUNT = "filterCollectionsCount";
export const TYPE_GET_COLLECTION_COUNT = "FilterCollectionInput!";
export const INPUT_GET_COLLECTION_COUNT = "filterCollectionInput";
export const RETURN_GET_COLLECTION_COUNT = `{
      count
      minPrice
      maxPrice
}`;
//#endregion
