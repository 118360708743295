import { Injectable } from "@angular/core";
import { Buyer } from "@core/auth/models/auth.models";
import { PaginationOptions } from "@shared/models/Graphql.interfaces";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GetDesignerByIdInput } from "../model/designers.model";
import { DesignersApi } from "./designers.api";

@Injectable({ providedIn: "root" })
export class DesignersFacade {
  constructor(private readonly designerApi: DesignersApi) {}

  getAllDesigners$(paginationOptions: PaginationOptions): Observable<Buyer[]> {
    return this.designerApi
      .getAllDesigners(paginationOptions)
      .pipe(map((x) => x.data.designersList));
  }

  getDesignerById$(designerId: string): Observable<Buyer> {
    const input: GetDesignerByIdInput = {
      getDesignerByIdInput: { designerId }
    };
    return this.designerApi
      .getDesignerById(input)
      .pipe(map((x) => x.data.designerById));
  }
}
