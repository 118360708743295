import { translateReturn } from "@shared/utils/utils.methods";

//#region all blogs
export const FUNC_POSTS = "posts";
export const INPUT_POSTS = "findPostsInput";
export const TYPE_POSTS = "FindPostsInput!";
export const RETURN_POSTS = `{
  _id
  image
  ${translateReturn("title")}
  ${translateReturn("description")}
  postTags{
    _id
    ${translateReturn("name")}
    slug
  }
}`;
//#endregion

//#region single blog
export const FUNC_SINGLE_POST = "post";
export const INPUT_POST = "findPostInput";
export const TYPE_POST = "FindPostInput!";
export const RETURN_SINGLE_POST = `{
  ${translateReturn("title")}
  ${translateReturn("description")}
  image
  postTags{
    _id
    ${translateReturn("name")}
    slug
  }

}`;

//#endregion

//#region related
export const FUNC_RELATED_BLOGS = "blogsByTagsId";
export const TYPE_RELATED_BLOGS = "RelatedBlogInput!";
export const INPUT_RELATED_BLOGS = "relatedBlogInput";

export const RETURN_RELATED_BLOGS = `{
  blogId
  image
  title
  titleAr
}`;
//   ${translateReturn('name')}

//#endregion
