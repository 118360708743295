import { Injectable } from '@angular/core';
import { BaseService } from '@core/graphql/base-service/base-service.service';
import { QueryFull } from '@core/models/graph-models';
import { Observable } from 'rxjs';
import { FUNC_SETTINGS, RETURNS_SETTINGS } from '../model/instructions.graphql';
import { SettingResponse } from '../model/instructions.model';

@Injectable({
  providedIn: "root"
})
export class InstructionsService {
  constructor(private readonly baseService: BaseService) {}

  getSetting(): Observable<SettingResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_SETTINGS;
    input.return = RETURNS_SETTINGS;
    return this.baseService.generalQuery(input);
  }
}
