import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import { Observable } from "rxjs";
import {
  FUNC_CREATE_REVIEW,
  INPUT_CREATE_REVIEW,
  RETURN_CREATE_REVIEW,
  TYPE_CREATE_REVIEW
} from "./review.graphql";
import { CreateReviewInput, CreateReviewResponse } from "./review.model";

@Injectable({ providedIn: "root" })
export class ReviewService {
  constructor(private readonly baseService: BaseService) {}

  createReview(variables: CreateReviewInput): Observable<CreateReviewResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_CREATE_REVIEW;
    input.variable = INPUT_CREATE_REVIEW;
    input.type = TYPE_CREATE_REVIEW;
    input.return = RETURN_CREATE_REVIEW;
    input.variables = variables;
    return this.baseService.generalMutationFull(input);
  }
}
