import { Buyer } from "@core/auth/models/auth.models";
import { ProductType } from "@shared/services/product/product.model";
import { ReviewType } from "@shared/services/review/review.model";

export interface ResponseInterface {
  message: string;
  link?: string;
  orderId?: string;
}
export interface Category {
  _id: string;
  name: string;
  arName: string;
  description: string;
  arDescription: string;
  image: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
  banner: string;
  displayOrder: number;
  inHome: boolean;
  subCategories: SubCategory[];
}

export interface SubCategory {
  _id: string;
  category: Category;
  name: string;
  arName: string;
  description: string;
  arDescription: string;
  image: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
}

export interface VariantType {
  variantId: string;
  price: number;
  qty: number;
  onSale: boolean;
  salePrice: number;
  images: string[];
  newArrival: boolean;
  available: boolean;
  freeShipping: boolean;
  dateCreated: string;
  dateUpdated: string;
  attributes: AttributeType[];
  category: Category;
  subCategory: SubCategory;
  attributeValues: ValueType[];
  reviews: ReviewType;
  product: ProductType;
}

export interface SubCategory {
  sub_id: string;
  name: string;
  arName: string;
  image: string;
  popular: boolean;
  category: Category;
  products: ProductType[];
}

export interface AttributeType {}

export interface ValueType {
  attributeValueId: string;
  name: string;
  arName: string;
  attribute: AttributeType;
}

export interface VariantType {
  variantId: string;
  price: number;
  qty: number;
  onSale: boolean;
  salePrice: number;
  images: string[];
  available: boolean;
  freeShipping: boolean;
  dateCreated: string;
  dateUpdated: string;
  attributes: AttributeType[];
  category: Category;
  subCategory: SubCategory;
  attributeValues: ValueType[];
  reviews: ReviewType;
  product: ProductType;
}

// export interface CountryType {
//   countryId: string;
//   name: string;
//   arName: string;
//   states: StateType[];
//   deliveryDays: number;
//   additionalShippingPercent: number;
// }

// export interface StateType {
//   stateId: string;
//   name: string;
//   arName: string;
//   cities: CityType[];
//   country: CountryType;
//   addresses: AddressType[];
// }

export interface CityType {
  _id: string;
  zoneId: string;
  name: string;
  arName: string;
  areas: AreaType[];
}

export interface AreaType {
  cityId: string;
  zoneId: string;
  _id: string;
  name: string;
  arName: string;
}

export interface QtyVariantId {
  qty: number;
  variantId: string;
}
// export interface Address {
//   addressId: string;
//   zipCode: string;
//   streetName: string;
//   buildingNo: string;
//   floorNo: string;
//   phoneNo: string;
//   buyer: Buyer;
//   country: CountryType;
//   city: CityType;
//   state: StateType;
// }

export interface AddressType {
  _id: string;
  city: string;
  cityId: string;
  phone: string;
  address: string;
}

export interface QtyVariantIdCartDetailId {
  qty: number;
  variantId: string;
  cartDetailId: string;
}

// Responses

export interface AllCategoriesResponse {
  data: { categories: Category[] };
}
export interface PopularCategoriesResponse {
  data: { popularCategories: Category[] };
}

export interface SubCategoryByCategoryIdResponse {
  data: { subCategoryByCategoryId: SubCategory[] };
}
export interface ResponseInterface {
  message: string;
}

export interface GetBuyerResponse {
  data: { user: Buyer };
}

export enum productListSortingEnum {
  DEFAULT = "default",
  POPULARITY = "popularity",
  RATING = "rating",
  LATEST = "latest",
  ASCPRICE = "ascPrice",
  DESCPRICE = "descPrice"
}
export interface ProductFilterCountType {
  count: number;
  minPrice: number;
  maxPrice: number;
}
