export const FUNC_DESIGNERS = "designersList";
export const RETURN_DESIGNERS = `{
  _id
  role
  status
  name
  image
  country
  city
}`;

export const FUNC_DESIGNER_BY_ID = "designerById";
export const INPUT_DESIGNER_BY_ID = "getDesignerByIdInput";
export const TYPE_DESIGNER_BY_ID = "GetDesignerByIdInput!";
export const RETURN_DESIGNER_BY_ID = `{
  _id
  role
  status
  name
  image
  country
  city
}`;
