import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import {
  INPUT_PAGINATION,
  TYPE_PAGINATION
} from "@shared/graphql/shared.graphql";
import { PaginationOptions } from "@shared/models/Graphql.interfaces";
import { Observable } from "rxjs";
import {
  FUNC_DESIGNERS,
  FUNC_DESIGNER_BY_ID,
  INPUT_DESIGNER_BY_ID,
  RETURN_DESIGNERS,
  TYPE_DESIGNER_BY_ID
} from "../model/designers.graphql";
import {
  DesignerByIdResponse,
  DesignersResponse,
  GetDesignerByIdInput
} from "../model/designers.model";

@Injectable({ providedIn: "root" })
export class DesignersApi {
  constructor(private readonly baseService: BaseService) {}

  getAllDesigners(
    paginationOption: PaginationOptions
  ): Observable<DesignersResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_DESIGNERS;
    input.pagination = INPUT_PAGINATION;
    input.paginationType = TYPE_PAGINATION;
    input.variables = paginationOption;
    input.return = RETURN_DESIGNERS;
    return this.baseService.generalQueryFull(input);
  }

  getDesignerById(
    designerByIdInput: GetDesignerByIdInput
  ): Observable<DesignerByIdResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_DESIGNER_BY_ID;
    input.variable = INPUT_DESIGNER_BY_ID;
    input.type = TYPE_DESIGNER_BY_ID;
    input.variables = designerByIdInput;
    input.return = RETURN_DESIGNERS;
    return this.baseService.generalQueryFull(input);
  }
}
