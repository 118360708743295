import { Injectable } from "@angular/core";
import { BaseService } from "@core/graphql/base-service/base-service.service";
import { QueryFull } from "@core/models/graph-models";
import {
  INPUT_PAGINATION,
  TYPE_PAGINATION
} from "@shared/graphql/shared.graphql";
import { Observable } from "rxjs";
import {
  FUNC_POSTS,
  RETURN_POSTS,
  INPUT_POSTS,
  TYPE_POSTS,
  FUNC_SINGLE_POST,
  INPUT_POST,
  TYPE_POST,
  RETURN_SINGLE_POST
} from "../../../pages/blog/model/blog.grapghql";
import {
  FindPostInput,
  FindPostsInput,
  NewsResponse,
  PostInputs,
  PostResponse,
  PostsResponse
} from "../../../pages/blog/model/blog.model";

@Injectable({ providedIn: "root" })
export class BlogService {
  constructor(private readonly baseService: BaseService) {}

  getPosts(variables: PostInputs): Observable<PostsResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_POSTS;
    input.pagination = INPUT_PAGINATION;
    input.paginationType = TYPE_PAGINATION;
    input.variable = INPUT_POSTS;
    input.type = TYPE_POSTS;
    input.variables = variables;
    input.return = RETURN_POSTS;
    return this.baseService.generalQueryFull(input);
  }

  getNews(variables: PostInputs): Observable<NewsResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_POSTS;
    input.pagination = INPUT_PAGINATION;
    input.paginationType = TYPE_PAGINATION;
    input.variable = INPUT_POSTS;
    input.type = TYPE_POSTS;
    input.variables = variables;
    input.return = RETURN_POSTS;
    return this.baseService.generalQueryFull(input);
  }

  getPostById(variables: FindPostInput): Observable<PostResponse> {
    const input = {} as QueryFull;
    input.func = FUNC_SINGLE_POST;
    input.variable = INPUT_POST;
    input.type = TYPE_POST;
    input.return = RETURN_SINGLE_POST;
    input.variables = variables;
    return this.baseService.generalQueryFull(input);
  }

  // getRelatedBlogs(variables: any): Observable<PostResponse> {
  //   const input = {} as QueryFull;
  //   input.func = FUNC_RELATED_BLOGS;
  //   input.type = TYPE_RELATED_BLOGS;
  //   input.return = RETURN_RELATED_BLOGS;
  //   input.variables = variables;
  //   input.variable = INPUT_RELATED_BLOGS;
  //   input.pagination = INPUT_PAGINATION;
  //   input.paginationType = TYPE_PAGINATION;
  //   return this.baseService.generalQueryFull(input);
  // }
}
