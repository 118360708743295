import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { ReviewService } from "./review.service";
import { CreateReviewInput, ReviewType } from "./review.model";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ReviewFacade {
  constructor(private reviewService: ReviewService) {}

  createReview$(input: CreateReviewInput): Observable<ReviewType> {
    return this.reviewService
      .createReview(input)
      .pipe(map((res) => res.data.createReview));
  }
}
