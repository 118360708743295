import { Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { removeBlankValuesFromKeys } from "@shared/utils/utils.methods";
import {
  AttributesFilter,
  AttributesToUi,
  CachedEnLangAttribute,
  FilterProductsInput,
  PropertyValue
} from "./product-list/product-list.model";
import { ProductType } from "./product/product.model";

@Injectable({ providedIn: "root" })
export class ProductsListFilters {
  private _productsList: ProductType[] = [];
  private _filterParams!: FilterProductsInput;
  private _attributesFilter: AttributesFilter[] = [];

  constructor(private readonly route: ActivatedRoute) {}

  public maniuplateAttributesFilter(attributes: AttributesToUi[]) {
    let selectedAttributes: number[] = [];
    const attributesMaped: AttributesFilter[] = attributes.map((x, j) => {
      let n = 0;

      return {
        property: x.spec,
        values: x.sentSpecValue.map((y, i) => {
          const currentParamsValue =
            this.route.snapshot.queryParams[x.sentSpec]?.split(",");
          let noOfSelectedAttributes: number = 0;
          const index = currentParamsValue?.indexOf(y);
          if (index > -1) {
            n += 1;
            selectedAttributes[j] = n;

            return { value: x.specValue[i], selected: true };
          }
          return { value: x.specValue[i], selected: false };
        }),
        sentProperty: x.sentSpec,
        sentValues: x.sentSpecValue.map((x) => {
          return { value: x };
        }),
        noOfSelected: selectedAttributes
      };
    });
    this._attributesFilter = attributesMaped;
  }

  public maniuplateFiltersParams(params: Params): FilterProductsInput {
    const filterParams: FilterProductsInput = {
      category: params["category"],
      attributeValues:
        this.changeAttributesValuesFromUrlToArray().length > 0
          ? this.changeAttributesValuesFromUrlToArray()
          : null,
      subCategories: params["subCategory"]
        ? params["subCategory"].split(",")
        : null,
      minPrice:
        params["minPrice"] && params["maxPrice"]
          ? parseInt(params["minPrice"])
          : null,
      maxPrice:
        params["minPrice"] && params["maxPrice"]
          ? parseInt(params["maxPrice"])
          : null,
      featured: params["featured"] ? true : null,
      onSale: params["onSale"] ? true : null,
      collectionId: params["collectionId"] ? params["collectionId"] : null,
      designer: params["designer"] ? params["designer"] : null,
      tag: params["tag"] ? params["tag"] : null,
      sorting: params["sorting"] ? params["sorting"] : null
    };

    return removeBlankValuesFromKeys(filterParams);
  }

  public set productsList(v: ProductType[]) {
    this._productsList = [...v];
  }

  public get productsList(): ProductType[] {
    return this._productsList;
  }

  public set filtersParams(v: FilterProductsInput) {
    this._filterParams = v;
  }

  public get filtersParams(): FilterProductsInput {
    return this._filterParams;
  }

  public set attributesFilter(v: AttributesFilter[]) {
    this._attributesFilter = v;
  }

  public get attributesFilter(): AttributesFilter[] {
    return this._attributesFilter;
  }

  private changeAttributesValuesFromUrlToArray(): PropertyValue[] {
    let result: PropertyValue[] = [];

    const attributesNames = this.attributesFilter.map(
      (attr) => attr.sentProperty
    );

    attributesNames.map((x) => {
      if (this.route.snapshot.queryParams[x]) {
        const splitedQueryValues: string[] =
          this.route.snapshot.queryParams[x].split(",");
        splitedQueryValues.forEach((y) => {
          const propertyValue: PropertyValue = {
            property: x,
            value: y
          };
          result.push(propertyValue);
        });
      }
    });
    return result;
  }
}
