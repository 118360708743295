import { Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import {
  FindPostInput,
  Post,
  PostInputs
} from "../../../pages/blog/model/blog.model";

import { BlogService } from "./blog.service";

@Injectable({ providedIn: "root" })
export class BlogFacade {
  private _allBlogsSubject: Subject<Post[]> = new Subject();
  private _singleBlogSubject: Subject<Post> = new Subject();
  private _relatedBlogsSubject: Subject<Post[]> = new Subject();

  constructor(private readonly blogService: BlogService) {}

  public loadBlogsApi(input: PostInputs): void {
    this.blogService.getPosts(input).subscribe((res) => {
      this._allBlogsSubject.next(res.data.posts);
    });
  }

  public loadBlogApi(id: string): void {
    this.setSingleBlog(id);
  }

  private setSingleBlog(id: string): void {
    const variables: FindPostInput = {
      findPostInput: { postId: id }
    };

    this.blogService
      .getPostById(variables)
      .pipe(
        switchMap((result1) => {
          this._singleBlogSubject.next(result1.data.post);
          // this.setRelatedBlogs(result1.data.post.tags[0]?.tagId || "1");
          return this._singleBlogSubject.asObservable();
        })
      )
      .subscribe();
  }

  private setRelatedBlogs(id: string): void {
    const variables = {
      relatedBlogInput: { tagId: id },
      paginationOptions: {
        page: 1,
        limit: 3
      }
    };
  }

  public getAllBlogs$(): Observable<Post[]> {
    return this._allBlogsSubject.asObservable();
  }

  public getBlogById$(): Observable<Post> {
    return this._singleBlogSubject.asObservable();
  }
  public getRelatedBlogs$(): Observable<Post[]> {
    return this._relatedBlogsSubject.asObservable();
  }
}
