import { InstructionsService } from './instructions.service';
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Setting } from "src/app/pages/home/model/home.model";

@Injectable({
  providedIn: "root"
})
export class InstructionsFacade {
  private _settingSubject: Subject<Setting> = new Subject();

  constructor(private readonly instructionsService: InstructionsService) {
    this.setSetting();
  }

  //#region Implementation
  private setSetting(): void {
    this.instructionsService.getSetting().subscribe((res) => {
      this._settingSubject.next(res.data.settings);
    });
  }
  //#endregion

  //#region Public methods (interface)
  public getSetting$(): Observable<Setting> {
    return this._settingSubject.asObservable();
  }
  //#endregion
}
