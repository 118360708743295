import { Injectable } from "@angular/core";
import { PaginationOptions } from "@shared/models/Graphql.interfaces";
import { ProductFilterCountType } from "@shared/models/Shared.interfaces";
import {
  AttributesToUi,
  CachedEnLangAttribute
} from "@shared/services/product-list/product-list.model";
import { ProductsListFacade } from "@shared/services/product-list/products-list.facade";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  AttributesByCollectionInput,
  CollectionAttributeValuesInput,
  CollectionType,
  FilterCollectionInput,
  GetCollectionInput
} from "../models/collection.model";
import { CollectionApi } from "./collection.api";

@Injectable({ providedIn: "root" })
export class CollectionFacade {
  constructor(
    private readonly collectionApi: CollectionApi,
    private readonly productListFacade: ProductsListFacade
  ) {}

  getAllCollections$(
    paginationOptions: PaginationOptions
  ): Observable<CollectionType[]> {
    return this.collectionApi
      .getAllCollections(paginationOptions)
      .pipe(map((x) => x.data.collections));
  }

  getCollection$(input: FilterCollectionInput): Observable<CollectionType> {
    return this.collectionApi
      .geCollection(input)
      .pipe(map((x) => x.data.filterCollections));
  }

  getCollectionCount$(
    input: FilterCollectionInput
  ): Observable<ProductFilterCountType> {
    return this.collectionApi
      .getFilteredCollectionCount(input)
      .pipe(map((x) => x.data.filterCollectionsCount));
  }

  getAttributesByCollectionIdEn$(
    collectionId: string
  ): Observable<AttributesToUi[]> {
    const input: AttributesByCollectionInput = {
      attributesByCollectionInput: { collectionId }
    };
    return this.collectionApi.getCachedAttributesByCollectionId(input).pipe(
      map((res) => {
        if (localStorage.getItem("lang") == "en") {
          const attributes = this.productListFacade.enAttributesManipulate(
            res.data.getCachedAttributesByCollection.cachedAttributes
          );
          return attributes;
        } else {
          const attributes =
            res.data.getCachedAttributesByCollection.cachedAttributes;
          const arAttributesAfterManipulation =
            this.productListFacade.arAttributesManipulate(attributes);
          return arAttributesAfterManipulation;
        }
      })
    );
  }
}
